#loading_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#loading_container > img{
    width: 100px;
    height: 100px;
    animation: loading_logo 2s linear infinite;
}
@keyframes loading_logo {
    0%{
    transform: scale(1);
    }
    50%{
    transform: scale(1.2);
    }
    100%{
     transform: scale(1);
    }
}
@media screen and (max-width: 970px){
.app_contianer{
    margin-top: 54.5px;
 }
}